import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { LuLayoutDashboard } from "react-icons/lu";
import { RxDashboard } from "react-icons/rx";
import { BsBuildingGear } from "react-icons/bs";
import { FiFileText, FiImage, FiPower, FiUsers } from "react-icons/fi";

import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../../../redux/actions/login";

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const slideDropdown = (event) => {
    event.preventDefault();

    let otherLi = $(`.${style.navbar} > li > a`)
      .not(event.target)
      .closest("li");

    otherLi.removeClass(style.active);
    otherLi.find("ul")?.slideUp();

    $(event.target).closest("li").toggleClass(style.active);
    $(event.target).next().slideToggle();
  };

  return (
    <aside className={`bg-white ${style.sidebar} border-end shadow-sm`}>
      <header
        className={
          "p-3 text-center bg-white text-primary h1 mb-0 fw-bold " +
          style.navHeader
        }
      >
        CMTMS
      </header>

      <ul className={`m-0 p-0 ${style.navbar}`}>
        <li className={style.active}>
          <Link to={"/admin"}>
            <LuLayoutDashboard className={style.navIcon} /> Dashboard
          </Link>
        </li>
        {user.role === "admin" ? (
          <>
            <li className={style.hasDropdown}>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={slideDropdown}>
                <RxDashboard className={style.navIcon} />
                Master
              </a>
              <ul>
                <li>
                  <Link to={"/admin/role"}>Role</Link>
                </li>
                <li>
                  <Link to={"/admin/material-type"}>Material Type</Link>
                </li>
                <li>
                  <Link to={"/admin/zone"}>Zone</Link>
                </li>
              </ul>
            </li>
            <li className={style.hasDropdown}>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={slideDropdown}>
                <BsBuildingGear className={style.navIcon} />
                Admin / Company
              </a>
              <ul>
                <li>
                  <Link to={"/admin/company/create"}>Create New Admin</Link>
                </li>
                <li>
                  <Link to={"/admin/company"}>View Admin</Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}
        {user.role === "company" || user.role === "admin" ? (
          <>
            <li className={style.hasDropdown}>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={slideDropdown}>
                <FiUsers className={style.navIcon} />
                User
              </a>
              <ul>
                {user.role === "company" ? (
                  <li>
                    <Link to={"/admin/user/create"}>Create New User</Link>
                  </li>
                ) : null}
                <li>
                  <Link to={"/admin/user"}>View User</Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}
        {user.role === "user" || user.role === "admin" ? (
          <>
            <li className={style.hasDropdown}>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={slideDropdown}>
                <FiUsers className={style.navIcon} />
                Stone Dust
              </a>
              <ul>
                {user.role === "user" ? (
                  <li>
                    <Link to={"/admin/stone-dust/create"}>
                      Create Stone Dust
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to={"/admin/stone-dust"}>View Stone Dust</Link>
                </li>
              </ul>
            </li>

            <li className={style.hasDropdown}>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={slideDropdown}>
                <FiFileText className={style.navIcon} />
                Forms
              </a>
              <ul>
                {user.role === "user" ? (
                  <li>
                    <Link to={"/admin/form/create"}>Create Form</Link>
                  </li>
                ) : null}
                <li>
                  <Link to={"/admin/form"}>View Form</Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}

        {user.role === "admin" ? (
          <li className={style.hasDropdown}>
            {/* eslint-disable-next-line */}
            <a href="#" onClick={slideDropdown}>
              <FiImage className={style.navIcon} />
              Resources
            </a>
            <ul>
              <li>
                <Link to={"/admin/gallery/create"}>Add</Link>
              </li>
              <li>
                <Link to={"/admin/gallery"}>View Resources</Link>
              </li>
            </ul>
          </li>
        ) : null}
        <li>
          {/* eslint-disable-next-line */}
          <a href="#" onClick={(event) => dispatch(doLogout(event))}>
            <FiPower className={style.navIcon} /> Logout
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
