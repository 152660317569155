import ApiExecute from ".";

const view = async (data = null) => {
  let params = "";
  if (data) {
    params = "?" + new URLSearchParams(data).toString();
  }
  return await ApiExecute(`form${params}`, {
    data,
  });
};

const store = async (data) => {
  return await ApiExecute("form", {
    method: "POST",
    data,
  });
};

const show = async (id) => {
  return await ApiExecute(`form/${id}`);
};

const update = async (id, data) => {
  return await ApiExecute(`form/${id}`, {
    method: "POST",
    data,
  });
};

const destory = async (id) => {
  return await ApiExecute(`form/${id}`, {
    method: "POST",
    data: {
      _method: "DELETE",
    },
  });
};

const formServices = {
  view,
  store,
  show,
  update,
  destory,
};

export default formServices;
