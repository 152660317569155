import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../constants/login";

const initialState = {
  isLoggedIn: false,
  token: null,
  user: null,
  loginError: null,
};

export default function loginReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload?.token,
        user: payload?.data,
        loginError: null,
        isLoggedIn: true,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        token: null,
        user: null,
        loginError: payload?.message,
        isLoggedIn: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        user: null,
        isLoggedIn: false,
      };

    default:
      return state;
  }
}
