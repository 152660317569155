import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Button, InputGroup } from "react-bootstrap";
import { useState } from "react";
import { FiCheck } from "react-icons/fi";
import * as Yup from "yup";
import passwordServices from "../../../../redux/services/password";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const ChangePasswordForm = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [pVisible, setPVisible] = useState(false);
  const [pVisible2, setPVisible2] = useState(false);

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required("Please enter new password"),
    confirm_password: Yup.string()
      .required("Please enter confirm password.")
      .oneOf([Yup.ref("password"), null], "Passwords must match."),
  });

  return (
    <div className="bg-white shadow-sm p-3 rounded">
      <h4 className="text-primary pb-2 border-bottom mb-3">Add New Zone</h4>
      {error ? <Alert variant="danger">{error}</Alert> : null}
      {success ? <Alert variant="success">{success}</Alert> : null}

      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={passwordSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let response = await passwordServices.change(values);
          setSubmitting(false);

          if (response.status) {
            resetForm();
            setSuccess(response.data.message);

            setTimeout(() => {
              setSuccess(null);
            }, 3 * 1000);
          } else {
            if (response?.data?.data?.message)
              setError(response?.data?.data?.message);
            else if (response?.error?.message)
              setError(response?.error?.message);

            setTimeout(() => {
              setError(null);
            }, 3 * 1000);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <InputGroup>
                <Field
                  type={pVisible ? "text" : "password"}
                  placeholder="New Password"
                  className="form-control"
                  name="password"
                  id="password"
                />
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => setPVisible((v) => !v)}
                >
                  {pVisible ? <BsEyeSlashFill /> : <BsEyeFill />}
                </Button>
              </InputGroup>
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="confirm_password" className="form-label">
                Confirm Password
              </label>
              <InputGroup>
                <Field
                  type={pVisible2 ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="form-control"
                  name="confirm_password"
                  id="confirm_password"
                />

                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => setPVisible2((v) => !v)}
                >
                  {pVisible2 ? <BsEyeSlashFill /> : <BsEyeFill />}
                </Button>
              </InputGroup>
              <ErrorMessage
                name="confirm_password"
                component="div"
                className="text-danger"
              />
            </div>

            <div>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> Update Password
                  </>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePasswordForm;
