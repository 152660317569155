// import { useEffect } from "react";
import { Pagination } from "react-bootstrap";

const LaravelPagination = ({ items, fetchData }) => {
  const handlePagination = (e, pageNo) => {
    e.preventDefault();
    fetchData(pageNo);
  };

  const pItems = [];
  let start = 2,
    end = items?.last_page - 1;

  if (items?.last_page > 7) {
    end = 6;
  }

  if (items?.current_page > 4) {
    start = items?.current_page - 3;
    end = items?.current_page + 3;

    if (end > items?.last_page - 1) {
      end = items?.last_page - 1;
      start = end - 6;

      if (start < 2) start = 2;
    }
  }

  if (start > 2) {
    pItems.push(
      <Pagination.Item href="/" onClick={() => false}>
        &hellip;
      </Pagination.Item>
    );
  }

  for (let pn = start; pn <= end; pn++) {
    pItems.push(
      <Pagination.Item
        href="/"
        onClick={(e) => handlePagination(e, pn)}
        key={pn}
        active={pn === items?.current_page}
      >
        {pn}
      </Pagination.Item>
    );
  }

  if (end < items?.last_page - 1) {
    pItems.push(
      <Pagination.Item href="/" onClick={() => false}>
        &hellip;
      </Pagination.Item>
    );
  }

  if (items?.last_page === 1) return null;

  return (
    <Pagination size="sm">
      <Pagination.Item
        href="/"
        onClick={(e) => handlePagination(e, 1)}
        active={items?.current_page === 1}
      >
        1
      </Pagination.Item>

      {pItems}
      <Pagination.Item
        href="/"
        onClick={(e) => handlePagination(e, items.last_page)}
        active={items?.current_page === items?.last_page}
      >
        {items.last_page}
      </Pagination.Item>
    </Pagination>
  );
};

export default LaravelPagination;
