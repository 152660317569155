import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Button } from "react-bootstrap";
import roleServices from "../../../../redux/services/role";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";

const RoleForm = ({ fetchRoles }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const formikRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    async function fetchRoleDetails() {
      let response = await roleServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("name", response.data.name);
          formikRef.current.setFieldValue("level", response.data.level);
        }
      }
    }

    if (id) {
      fetchRoleDetails();
    }
  }, [id]);

  return (
    <div className="bg-white shadow-sm p-3 rounded">
      <h4 className="text-primary pb-2 border-bottom mb-3">Add New Role</h4>
      {error ? <Alert variant="danger">{error}</Alert> : null}

      <Formik
        initialValues={{
          name: "",
          level: 0,
        }}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};
          if (!values?.name) errors.name = "Please enter name.";
          if (!/\d+$/.test(values?.level)) {
            errors.level = "Please enter valid number";
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          let response = !id
            ? await roleServices.store(values)
            : await roleServices.update(id, values);
          setSubmitting(false);

          if (response.status) {
            id ? navigate("/admin/role") : fetchRoles();

            if (!id) {
              setValues({
                name: "",
                level: 0,
              });
            }
          } else {
            if (response?.data?.data?.message)
              setError(response?.data?.data?.message);
            else if (response?.error?.message)
              setError(response?.error?.message);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <Field
                type="text"
                placeholder="Name"
                className="form-control"
                name="name"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
                id="name"
              />
            </div>
            <div className="mb-3">
              <Field
                type="number"
                placeholder="Level"
                className="form-control"
                name="level"
                min="0"
              />
              <ErrorMessage
                name="level"
                component="div"
                className="text-danger"
                id="level"
              />
            </div>

            <div className={!id ? "d-grid" : null}>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RoleForm;
