import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import galleryServices from "../../../redux/services/galleryServices";
import { Col, Container, Row } from "react-bootstrap";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { FaRegCirclePlay } from "react-icons/fa6";

const WebGallery = () => {
  const [galleries, setGalleries] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    async function fetchGalleries() {
      try {
        let apiResponse = await galleryServices.webView();

        if (apiResponse.status) {
          setGalleries(apiResponse?.data);
        }
      } catch (error) {
        console.warn("Errors: ", error);
      }
    }

    fetchGalleries();
  }, []);

  useEffect(() => {
    if (galleries?.length)
      setVideoData(
        galleries
          ?.filter((g) => g.type !== "file")
          ?.map((g) => ({
            url:
              g.type === "image"
                ? g.image
                : `https://www.youtube.com/embed/${g.youtube_id}`,
            type: g.type === "image" ? "photo" : "video",
            altTag: g.title,
            title: g.title,
          }))
      );
  }, [galleries]);

  return (
    <>
      {activeIndex !== null ? (
        <div className="LightboxContainer">
          <ReactImageVideoLightbox
            data={videoData}
            startIndex={activeIndex}
            showResourceCount={true}
            onCloseCallback={() => setActiveIndex(null)}
          />
        </div>
      ) : null}
      <section class="banner inner text-white text-center">
        <div class="overlay"></div>
        <video
          src="video/background.mp4"
          class="video-bg"
          loop
          autoPlay
          muted
        ></video>

        <div class="banner-content">
          <h1 class="wow animate__backInDown text-white" data-wow-duration="2s">
            Resources
          </h1>
          <p class="wow animate__fadeInUp" data-wow-duration="2s">
            <Link to={"/"} className="text-white">
              Home
            </Link>
            <span className="mx-3">&raquo;</span>
            Resources
          </p>
        </div>
      </section>

      <section className="py-5">
        <Container>
          {galleries?.filter((g) => g.type === "file")?.length ? (
            <>
              <h3 className="mb-3 border-start border-5 p-3 border-primary bg-white text-upercase">
                Download Documents
              </h3>
              <Row>
                {galleries
                  ?.filter((g) => g.type === "file")
                  ?.map((gallery, index) => (
                    <Col sm={4} lg={3} key={index} className="mb-3">
                      <a
                        target="_blank"
                        href={gallery.file}
                        rel="noreferrer"
                        className="d-block text-dark text-decoration-none"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div className="gallery-image position-relative border rounded overflow-hidden p-5 bg-white">
                          <img
                            src={
                              "https://cdn.icon-icons.com/icons2/886/PNG/512/file_Image_download_icon-icons.com_68942.png"
                            }
                            className="w-100 gallery-image bg-white "
                            alt={gallery.title}
                            style={{ height: "100%" }}
                          />
                        </div>
                        <h5 className="mt-1 text-center ">{gallery.title}</h5>
                      </a>
                    </Col>
                  ))}
              </Row>
            </>
          ) : null}
          {galleries?.filter((g) => g.type !== "file")?.length ? (
            <>
              <h3 className="mb-3 border-start border-5 p-3 border-primary bg-white text-upercase">
                Images &amp; Videos
              </h3>
              <Row>
                {galleries
                  ?.filter((g) => g.type !== "file")
                  ?.map((gallery, index) => (
                    <Col sm={4} lg={3} key={index} className="mb-3">
                      <a
                        onClick={() => setActiveIndex(index)}
                        className="d-block text-dark text-decoration-none"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div className="gallery-image position-relative">
                          <img
                            src={
                              gallery.type === "image"
                                ? gallery.image
                                : `https://img.youtube.com/vi/${gallery.youtube_id}/maxresdefault.jpg`
                            }
                            className="w-100 gallery-image border rounded"
                            alt={gallery.title}
                          />
                          {gallery.type === "video" ? (
                            <div
                              className="position-absolute top-50 start-50 translate-middle fs-1  text-white"
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgba(0,0,0,0.5)",
                              }}
                            >
                              <FaRegCirclePlay />
                            </div>
                          ) : null}
                        </div>
                        <h5 className="mt-1 text-center ">{gallery.title}</h5>
                      </a>
                    </Col>
                  ))}
              </Row>
            </>
          ) : null}
        </Container>
      </section>
    </>
  );
};

export default WebGallery;
