import ApiExecute from ".";

const change = async (data) => {
  return await ApiExecute("change-password", {
    method: "POST",
    data,
  });
};

const passwordServices = {
  change,
};

export default passwordServices;
