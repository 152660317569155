import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header";
import { Container } from "react-bootstrap";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

const AdminLayout = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className="d-flex vh-100">
      <Sidebar />
      <section className="flex-grow-1 bg-light d-flex flex-column w-100">
        <Header />
        <main className="flex-grow-1 overflow-auto mb-3">
          <Container fluid>
            <Outlet />
          </Container>
        </main>
        <footer className="py-3">
          <Container fluid>
            &copy; 2023. All rights reserved Construction Material Testing
            Management System
          </Container>
        </footer>
      </section>
    </div>
  );
};

export default AdminLayout;
