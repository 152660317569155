import Swal from "sweetalert2";
import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../constants/login";
import loginServices from "../services/loginServices";

export const doLogin = (payload) => async (dispatch) => {
  let response = await loginServices.login(payload);

  return dispatch({
    type: response.status ? LOGIN_SUCCESS : LOGIN_FAILED,
    payload: response.status ? response.data : response?.error?.response?.data,
  });
};

export const doLogout = (event) => (dispatch) => {
  event.preventDefault();

  Swal.fire({
    icon: "warning",
    title: "Are you sure?",
    text: "You're going to logout from panel.",
    confirmButtonText: "Yes, Proceed",
    showCancelButton: true,
    cancelButtonText: "No, Cancel it",
    confirmButtonColor: "#c0392b",
    cancelButtonColor: "#2c3e50",
  }).then(async (result) => {
    if (result.isConfirmed) {
      // alert("Success");
      // let response = await loginServices.logout();

      // if (response.status) {
      sessionStorage.removeItem("@token");
      sessionStorage.removeItem("@user");

      return dispatch({
        type: LOGOUT_SUCCESS,
      });
      // }
    }
  });
};
