import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import formServices from "../../../../redux/services/formServices";
import materialTypeServices from "../../../../redux/services/materialTypeServices";
import { useSelector } from "react-redux";
import moment from "moment";

const FormFormat = () => {
  const user = useSelector((state) => state?.auth?.user);

  const [error, setError] = useState(null);
  const [mTypes, setMTypes] = useState([]);
  const [mTypes2, setMTypes2] = useState([]);
  const [mTypes3, setMTypes3] = useState([]);
  const navigate = useNavigate();
  const formikRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    async function fetchFormDetails() {
      let response = await formServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          let data = {
            date: response.data.date,
            material_type_id: response.data.material_type_id,
            tower: response.data.tower,
            drawing_no: response.data.drawing_no,
            structure_name: response.data.structure_name,
            location: response.data.location,
            concrete_grade: response.data.concrete_grade,
            quantity: response.data.quantity,
            eng_name: response.data.eng_name,
            pdf_file: null,
          };
          formikRef.current.setValues(data);
        }
      }
    }

    async function fetchParents() {
      let response = await materialTypeServices.view({ type: "root" });

      if (response.status) {
        setMTypes(response.data);
        setMTypes2([]);
        setMTypes3([]);
      }
    }

    fetchParents();

    if (id) {
      fetchFormDetails();
    }
  }, [id]);

  const fetchChildren = async (parent_id) => {
    let response = await materialTypeServices.view({ parent_id });

    if (response.status) {
      setMTypes2(response.data);
      setMTypes3([]);
    }
  };

  const fetchChildren2 = async (parent_id) => {
    let response = await materialTypeServices.view({ parent_id });

    if (response.status) {
      setMTypes3(response.data);
    }
  };

  return (
    <div className="bg-white shadow-sm p-3 rounded">
      <h4 className="text-primary pb-2 border-bottom mb-3">Add New Form</h4>
      {error ? <Alert variant="danger">{error}</Alert> : null}

      <Formik
        initialValues={{
          date: moment(new Date()).format("YYYY-MM-DD"),
          material_type_id: "",
          material_type_id2: "",
          material_type_id3: "",
          tower: "",
          floor: "",
          // drawing_no: "",
          // structure_name: "",
          location: "",
          // concrete_grade: "",
          // quantity: "",
          eng_name: "",
          quality_eng_name: "",
          pdf_file: null,
        }}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};
          if (!values?.material_type_id)
            errors.material_type_id = "Please enter material type.";

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          let formData = new FormData();
          if (id) formData.append("_method", "PUT");
          for (const key in values) {
            formData.append(key, values[key]);
          }
          let response = !id
            ? await formServices.store(formData)
            : await formServices.update(id, formData);
          setSubmitting(false);

          if (response.status) {
            navigate("/admin/form");

            if (!id) {
              setValues({
                date: null,
                material_type_id: "",
                tower: "",
                drawing_no: "",
                structure_name: "",
                location: "",
                concrete_grade: "",
                quantity: "",
                eng_name: "",
                pdf_file: null,
              });
            }
          } else {
            if (response?.data?.data?.message)
              setError(response?.data?.data?.message);
            else if (response?.error?.message)
              setError(response?.error?.message);
          }
        }}
      >
        {({
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <div className="row">
              <div className="col-sm-4 mb-3">
                <label htmlFor="project_name" className="form-label">
                  Project Name *
                </label>
                <div className="form-control bg-dark text-white border-dark">
                  {user?.admin?.name}
                </div>
              </div>
              <div className="col-sm-4 offset-sm-4 mb-3">
                <label htmlFor="" className="form-label">
                  Date
                </label>
                <div className="form-control bg-secondary text-light">
                  {values.date}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="material_type_id" className="form-label">
                    Select Form *
                  </label>
                  <select
                    type="text"
                    className="form-select"
                    name="material_type_id"
                    id="material_type_id"
                    onChange={(e) => {
                      handleChange(e);
                      fetchChildren(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.material_type_id}
                  >
                    <option value="">Select Form</option>
                    {mTypes.map((mType, index) => (
                      <option value={mType.id} key={index}>
                        {mType.name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="material_type_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {mTypes2.length ? (
                <div className="col-sm-4">
                  <div className="mb-3">
                    <label htmlFor="material_type_id2" className="form-label">
                      Select Form 2 *
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      name="material_type_id2"
                      id="material_type_id2"
                      onChange={(e) => {
                        handleChange(e);
                        fetchChildren2(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values?.material_type_id2}
                    >
                      <option value="">Select Form 2</option>
                      {mTypes2.map((mType, index) => (
                        <option value={mType.id} key={index}>
                          {mType.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="material_type_id2"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              ) : null}

              {mTypes3.length ? (
                <div className="col-sm-4">
                  <div className="mb-3">
                    <label htmlFor="material_type_id3" className="form-label">
                      Select Form 3 *
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      name="material_type_id3"
                      id="material_type_id3"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values?.material_type_id3}
                    >
                      <option value="">Select Form</option>
                      {mTypes3.map((mType, index) => (
                        <option value={mType.id} key={index}>
                          {mType.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="material_type_id3"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              ) : null}

              <div className="col-sm-4">
                <label htmlFor="report" className="form-label">
                  Report
                </label>
                <select
                  name="report"
                  className="form-select"
                  id="report"
                  value={values?.report}
                >
                  <option value="">Select Report</option>
                  <option value="Site">Site</option>
                  <option value="Third Party">Third Party</option>
                  <option value="MTC">MTC</option>
                  <option value="Calibration">Calibration</option>
                </select>
              </div>
              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="location" className="form-label">
                    Location
                  </label>
                  <Field
                    type="text"
                    placeholder="Location"
                    className="form-control"
                    name="location"
                    id="location"
                  />
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="tower" className="form-label">
                    Tower
                  </label>
                  <Field
                    type="text"
                    placeholder="Tower"
                    className="form-control"
                    name="tower"
                    id="tower"
                  />
                  <ErrorMessage
                    name="tower"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="floor" className="form-label">
                    Floor
                  </label>
                  <Field
                    type="text"
                    placeholder="Floor"
                    className="form-control"
                    name="floor"
                    id="floor"
                  />
                  <ErrorMessage
                    name="floor"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              {/* <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="concrete_grade" className="form-label">
                    Concrete Grade
                  </label>
                  <Field
                    type="text"
                    placeholder="Concrete Grade"
                    className="form-control"
                    name="concrete_grade"
                    id="concrete_grade"
                  />
                  <ErrorMessage
                    name="concrete_grade"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div> */}

              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="eng_name" className="form-label">
                    Engineer Name
                  </label>
                  <Field
                    type="text"
                    placeholder="Engineer Name"
                    className="form-control"
                    name="eng_name"
                    id="eng_name"
                  />
                  <ErrorMessage
                    name="eng_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="quality_eng_name" className="form-label">
                    Name of Quality Engineer
                  </label>
                  <Field
                    type="text"
                    placeholder="Name of Quality Engineer"
                    className="form-control"
                    name="quality_eng_name"
                    id="quality_eng_name"
                  />
                  <ErrorMessage
                    name="quality_eng_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="quantity" className="form-label">
                    Quantity
                  </label>
                  <Field
                    type="text"
                    placeholder="Quantity"
                    className="form-control"
                    name="quantity"
                    id="quantity"
                  />
                  <ErrorMessage
                    name="quantity"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div> */}

              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="pdf_file" className="form-label">
                    Upload File
                  </label>
                  <input
                    type="file"
                    name="pdf_file"
                    id="pdf_file"
                    className="form-control"
                    onChange={(e) => {
                      console.log("pdf file: ", e.target.files);
                      setFieldValue("pdf_file", e.target.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="pdf_file"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className="px-5"
                variant="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormFormat;
