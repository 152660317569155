import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChangePasswordForm from "./Form";

const ChangePassword = () => {
  return (
    <div>
      <h1>Change Password</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
      </Breadcrumb>

      <ChangePasswordForm />
    </div>
  );
};

export default ChangePassword;
