import axios from "axios";
import { API_BASE_URL } from "../../configs/constants.config";

const ApiExecute = async (url, params = {}) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (params?.data) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (!params.is_guest) {
    let token = sessionStorage.getItem("@token");

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  }

  let instance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 5 * 1000,
    headers,
  });

  let method = params.method ?? "GET";
  let data = params.data ?? null;

  return instance
    .request({
      url,
      method,
      data,
    })
    .then((res) => {
      return {
        status: true,
        data: res.data,
      };
    })
    .catch((err) => {
      return {
        status: false,
        error: err,
        data: err?.response?.data ?? err?.message,
      };
    });
};
export default ApiExecute;
