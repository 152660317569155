import ApiExecute from ".";

const view = async () => {
  return await ApiExecute("role");
};

const store = async (data) => {
  return await ApiExecute("role", {
    method: "POST",
    data,
  });
};

const show = async (id) => {
  return await ApiExecute(`role/${id}`);
};

const update = async (id, data) => {
  data._method = "PUT";
  return await ApiExecute(`role/${id}`, {
    method: "POST",
    data,
  });
};

const destory = async (id) => {
  return await ApiExecute(`role/${id}`, {
    method: "POST",
    data: {
      _method: "DELETE",
    },
  });
};

const roleServices = {
  view,
  store,
  show,
  update,
  destory,
};

export default roleServices;
