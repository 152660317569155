import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Spinner, Table } from "react-bootstrap";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import galleryServices from "../../../redux/services/galleryServices";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companies, setGalleries] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    async function fetchGalleries() {
      setLoading(true);
      setError(null);
      let response = await galleryServices.view();
      setLoading(false);
      if (response.status) {
        setGalleries(response?.data);
      } else {
        if (response?.data?.data?.message)
          setError(response?.data?.data?.message);
        else if (response?.error?.message) setError(response?.error?.message);
      }
    }
    fetchGalleries();
  }, [refresh]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await galleryServices.destory(id);

        if (response.status) {
          setRefresh((r) => parseInt(r) + 1);
        }
      }
    });
  };

  return (
    <div>
      <h1>Gallery</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">View Galleries</h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        {!loading ? (
          <div>
            {companies.length ? (
              <Table bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Preview</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{row.title}</td>
                      <td>{row.type}</td>
                      <td>
                        {row.type === "image" ? (
                          <a
                            href={`${row.image}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={row.image}
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: "contain",
                              }}
                              alt={row.title}
                            />
                          </a>
                        ) : (
                          <>
                            <a
                              href={
                                row.type === "video"
                                  ? `https://www.youtube.com/watch?v=${row.youtube_id}`
                                  : row.file
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm btn-outline-primary"
                            >
                              Preview
                            </a>
                          </>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/admin/gallery/edit/${row.id}`}
                          className="btn btn-link text-primary"
                        >
                          <MdModeEdit />
                        </Link>
                        <Button
                          variant="link"
                          className="text-danger"
                          onClick={() => handleDelete(row.id)}
                        >
                          <MdDelete />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div>No record(s) found.</div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
