import { Breadcrumb, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import MaterialTypeForm from "./Form";
import Swal from "sweetalert2";
import materialTypeServices from "../../../redux/services/materialTypeServices";

const MaterialType = () => {
  const [loading, setLoading] = useState(false);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    async function fetchMaterialTypes() {
      setLoading(true);
      let response = await materialTypeServices.view();
      setLoading(false);

      if (response.status) {
        setMaterialTypes(response?.data);
      }
    }

    fetchMaterialTypes();
  }, [refresh]);

  const refreshMaterialTypes = () => {
    setRefresh((r) => parseInt(r) + 1);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await materialTypeServices.destory(id);

        if (response.status) {
          setRefresh((r) => parseInt(r) + 1);
        }
      }
    });
  };

  return (
    <div>
      <h1>Material Type</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Material Type</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={4}>
          <MaterialTypeForm fetchMaterialTypes={refreshMaterialTypes} />
        </Col>

        <Col sm={8}>
          <div className="bg-white shadow-sm p-3 rounded">
            <h4 className="text-primary pb-2 border-bottom mb-3">
              View Material Types
            </h4>
            {loading ? (
              <div className="text-center">
                <Spinner variant="primary" />
              </div>
            ) : (
              <div>
                {materialTypes?.length ? (
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Parent</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materialTypes?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{item.name}</td>
                          <td>{item.material_type?.name ?? "ROOT"}</td>
                          <td>
                            <Link
                              to={`/admin/material-type/edit/${item.id}`}
                              className="btn btn-link text-primary"
                            >
                              <MdModeEdit />
                            </Link>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() => handleDelete(item.id)}
                            >
                              <MdDelete />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>No record(s) found.</div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MaterialType;
