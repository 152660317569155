import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Layout from "./screens/web/Layout";
import Dashboard from "./screens/admin/Dashboard";
import Role from "./screens/admin/Role";
import Home from "./screens/web/Home";
import AdminLayout from "./screens/admin/Layout";
import NotFound from "./screens/admin/NotFound";
import Login from "./screens/admin/Login";
import CreateCompany from "./screens/admin/Company/CreateCompany";
import Company from "./screens/admin/Company";
import EditRole from "./screens/admin/Role/Edit";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "./redux/constants/login";
import MaterialType from "./screens/admin/MaterialType";
import EditMaterialType from "./screens/admin/MaterialType/Edit";
import Zone from "./screens/admin/Zone";
import EditZone from "./screens/admin/Zone/Edit";
import User from "./screens/admin/User";
import CreateUser from "./screens/admin/User/CreateUser";
import StoneDust from "./screens/forms/StoneDust";
import CreateStoneDust from "./screens/forms/StoneDust/CreateStoneDust";
import Form from "./screens/forms/Form";
import AddForm from "./screens/forms/Form/Add";
import EditForm from "./screens/forms/Form/Edit";
import ChangePassword from "./screens/admin/ChangePassword";
import Gallery from "./screens/admin/Gallery";
import CreateGallery from "./screens/admin/Gallery/CreateGallery";
import WebGallery from "./screens/web/Gallery";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    function checkLogin() {
      setLoading(true);
      let token = sessionStorage.getItem("@token"),
        user = sessionStorage.getItem("@user");

      if (token) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token,
            data: JSON.parse(user),
          },
        });
      }
      setLoading(false);
    }

    checkLogin();
  }, [dispatch]);

  return !loading ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="gallery" element={<WebGallery />} />
        </Route>

        <Route path="/login" element={<Login />} />

        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="role" element={<Role />} />
          <Route path="role/edit/:id" element={<EditRole />} />
          <Route path="material-type" element={<MaterialType />} />
          <Route path="material-type/edit/:id" element={<EditMaterialType />} />
          <Route path="zone" element={<Zone />} />
          <Route path="zone/edit/:id" element={<EditZone />} />
          <Route path="company" element={<Company />} />
          <Route path="company/create" element={<CreateCompany />} />
          <Route path="company/edit/:id" element={<CreateCompany />} />
          <Route path="user" element={<User />} />
          <Route path="user/create" element={<CreateUser />} />
          <Route path="user/edit/:id" element={<CreateUser />} />

          <Route path="stone-dust" element={<StoneDust />} />
          <Route path="stone-dust/create" element={<CreateStoneDust />} />
          <Route path="stone-dust/edit/:id" element={<CreateStoneDust />} />

          <Route path="form" element={<Form />} />
          <Route path="form/create" element={<AddForm />} />
          <Route path="form/edit/:id" element={<EditForm />} />

          <Route path="gallery" element={<Gallery />} />
          <Route path="gallery/create" element={<CreateGallery />} />
          <Route path="gallery/edit/:id" element={<CreateGallery />} />

          <Route path="change-password" element={<ChangePassword />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
      <Spinner />
    </div>
  );
}

export default App;
