import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import zoneServices from "../../../../redux/services/zoneServices";
import materialTypeServices from "../../../../redux/services/materialTypeServices";

const ZoneForm = ({ fetchZones }) => {
  const [error, setError] = useState(null);
  const [materialTypes, setMaterialTypes] = useState([]);
  const navigate = useNavigate();
  const formikRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    async function fetchZoneDetails() {
      let response = await zoneServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("name", response.data.name);
          formikRef.current.setFieldValue(
            "material_type_id",
            response.data.material_type_id
          );
        }
      }
    }

    if (id) {
      fetchZoneDetails();
    }
  }, [id]);

  useEffect(() => {
    async function fetchMaterialTypes() {
      let response = await materialTypeServices.view();

      if (response.status) {
        setMaterialTypes(response?.data);
      }
    }
    fetchMaterialTypes();
  }, []);

  return (
    <div className="bg-white shadow-sm p-3 rounded">
      <h4 className="text-primary pb-2 border-bottom mb-3">Add New Zone</h4>
      {error ? <Alert variant="danger">{error}</Alert> : null}

      <Formik
        initialValues={{
          name: "",
          material_type_id: null,
        }}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};
          if (!values?.name) errors.name = "Please enter name.";
          if (!values?.material_type_id)
            errors.material_type_id = "Please select material type.";

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          let response = !id
            ? await zoneServices.store(values)
            : await zoneServices.update(id, values);
          setSubmitting(false);

          if (response.status) {
            id ? navigate("/admin/zone") : fetchZones();

            if (!id) {
              setValues({
                name: "",
                material_type_id: null,
              });
            }
          } else {
            if (response?.data?.data?.message)
              setError(response?.data?.data?.message);
            else if (response?.error?.message)
              setError(response?.error?.message);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="material_type_id" className="form-label">
                Select Material Type
              </label>
              <Field
                component="select"
                name="material_type_id"
                id="material_type_id"
                className="form-select"
              >
                <option value="">Select Material Type</option>
                {materialTypes.map((mtype, index) => (
                  <option value={mtype.id} key={index}>
                    {mtype.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="material_type_id"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <Field
                type="text"
                placeholder="Name"
                className="form-control"
                name="name"
                id="name"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>

            <div className={!id ? "d-grid" : null}>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ZoneForm;
