import ApiExecute from ".";

const view = async () => {
  return await ApiExecute("company");
};

const store = async (data) => {
  return await ApiExecute("admin", {
    method: "POST",
    data,
  });
};

const show = async (id) => {
  return await ApiExecute(`admin/${id}`);
};

const update = async (id, data) => {
  data._method = "PUT";
  return await ApiExecute(`admin/${id}`, {
    method: "POST",
    data,
  });
};

const destory = async (id) => {
  return await ApiExecute(`admin/${id}`, {
    method: "POST",
    data: {
      _method: "DELETE",
    },
  });
};

const companyServices = {
  view,
  store,
  show,
  update,
  destory,
};

export default companyServices;
