import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Breadcrumb, Button, FormCheck } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import userServices from "../../../redux/services/userServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
// import roleServices from "../../../redux/services/role";

const CreateStoneDust = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();

  const [tableData, setTableData] = useState([
    {
      sieve: 10,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [100],
      zone2: [100],
      zone3: [100],
    },
    {
      sieve: 4.75,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [90,100],
      zone2: [90,100],
      zone3: [90,100],
    },
    {
      sieve: 2.36,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [60,95],
      zone2: [75,100],
      zone3: [85,100],
    },
    {
      sieve: 1.18,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [30,70],
      zone2: [55,90],
      zone3: [75,100],
    },
    {
      sieve: 0.6,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [15,34],
      zone2: [35,59],
      zone3: [60,79],
    },
    {
      sieve: 0.3,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [5,20],
      zone2: [8,30],
      zone3: [12,40],
    },
    {
      sieve: 0.15,
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: [0,20],
      zone2: [0,20],
      zone3: [0,20],
    },
    {
      sieve: "Pan",
      weight: 0,
      percent_wt: 0,
      commulative_retained: 0,
      commulative_passing: 0,
      zone1: null,
      zone2: null,
      zone3: null,
    },
  ]);

  const [totalWeight, setTotalWeight] = useState(0);
  const [totalPassing, setTotalPassing] = useState(0);

  const [error, setError] = useState(null);
  // const [roles, setRoles] = useState([]);

  useEffect(() => {
    let totalWt = 0, totalPassing = 0;
    for (let tData of tableData) {
      totalWt += parseFloat(tData.weight);
      totalPassing += parseFloat(tData.commulative_passing)
    }
    setTotalWeight(totalWt);
    setTotalPassing(totalPassing)
  }, [tableData]);

  useEffect(() => {
    if(!totalWeight) return;

    let newTableData = [...tableData]

    let tRow;
    let comm_retained = 0, totalPassing = 0;
    for (var index in newTableData) {
      tRow = newTableData[index];
      tRow.percent_wt = Math.round((parseFloat(tRow.weight) / parseFloat(totalWeight)) * 100)
      comm_retained += Math.round(tRow.percent_wt,2);
      tRow.commulative_retained  = comm_retained;
    }

    totalPassing = comm_retained;
    for (var index in newTableData) {
      tRow = newTableData[index];

      // comm_passing -= Math.round(tRow.commulative_retained,2);
      tRow.commulative_passing  = totalPassing - tRow.commulative_retained;
    }

    setTableData(newTableData);
  }, [totalWeight])
  
  useEffect(() => {
    async function fetchStoneDustDetails() {
      let response = await userServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("name", response.data.name);
          formikRef.current.setFieldValue("login", response.data.login);
          formikRef.current.setFieldValue("role_id", response.data.role_id);
        }
      }
    }

    if (id) {
      fetchStoneDustDetails();
    }
  }, [id]);

  const handleWeight = (event, index) => {
    setTableData((tData) =>
      tData.map((td, i) => {
        if (i === index) {
          td.weight = event.target.value;
        }
        return td;
      })
    );
  };

  return (
    <div>
      <h1>{id ? "Edit" : "Add"} Stone Dust</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/user" }}>
          Stone Dust
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {id ? "Edit" : "Add"} Stone Dust
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">Add Stone Dust</h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        <Formik
          innerRef={formikRef}
          initialValues={{
            role_id: null,
            name: "",
            login: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.role_id) {
              errors.role_id = "* Please select role.";
            }
            if (!values.name) {
              errors.name = "* Please enter name.";
            }
            if (!values.login) {
              errors.login = "* Please enter username.";
            }
            if (!values.password && !id) {
              errors.password = "* Please enter password.";
            } else if (
              values.password.length >= 1 &&
              values.password.length < 8
            ) {
              errors.password = "* Please enter min 8 character password.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError(null);
            let response = !id
              ? await userServices.store(values)
              : await userServices.update(id, values);
            setSubmitting(false);

            if (response.status) {
              navigate("/admin/user");
            } else {
              // console.log("admin error", response?.data?.data);
              if (response?.data?.data?.message)
                setError(response?.data?.data?.message);
              else if (response?.error?.message)
                setError(response?.error?.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <div className="row align-items-center">
                  <label
                    htmlFor="role_id"
                    className="form-label col-sm-4 col-lg-4 mb-3"
                  >
                    Select Material Type
                  </label>
                  <div className="col-sm-8 col-lg-8">
                    <Field
                      component="select"
                      name="role_id"
                      id="role_id"
                      className="form-select"
                    >
                      <option value="">Select Material Type</option>
                      <option value="Crushed stone Sand">
                        Crushed stone Sand
                      </option>
                      <option value="Stone Dust">Stone Dust</option>
                      <option value="Fine Aggregates">Fine Aggregates</option>
                    </Field>
                  </div>
                </div>
                <ErrorMessage
                  name="role_id"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <div className="form-label fw-bold">
                  1. Vehicle Reporting at Gate
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <label htmlFor="" className="form-label">
                      Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="" className="form-label">
                      Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                    {/* <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                      id="name"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div htmlFor="name" className="form-label fw-bold">
                  2. Vehicle Reporting at Store *
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                    {/* <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                      id="name"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div htmlFor="name" className="form-label fw-bold">
                  3. Vehicle Reporting at QC Lab *
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div htmlFor="name" className="form-label fw-bold">
                  4. Vehicle Verification (Transit Pass issued by Department of
                  Mines and Geology)
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Source
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Dispatch Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Dispatch Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Vehicle No.
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      License Number
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Quantity
                    </label>
                    <Field
                      type="number"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Upload Transit Pass
                    </label>
                    <Field
                      type="file"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Material is Verified
                    </label>
                    <div>
                      <FormCheck
                        inline
                        name="materialIsVerified"
                        label="Yes"
                        type="radio"
                        id="materialIsVerified"
                      />
                      <FormCheck
                        inline
                        name="materialIsVerified"
                        label="No"
                        type="radio"
                        id="materialIsNotVerified"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div htmlFor="name" className="form-label fw-bold">
                  5. Sampling
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sample Collection Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sample Collection Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sample taken from top, side and bottom
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Upload Pic of Sample collection
                    </label>
                    <Field
                      type="file"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      accept="*/image"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div htmlFor="name" className="form-label fw-bold">
                  6. Testing Starting
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Start Date
                    </label>
                    <Field
                      type="date"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Start Time
                    </label>
                    <Field
                      type="time"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-sm-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Protocol
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered table-sm align-middle text-center">
                  <thead className="text-center table-primary">
                    <tr>
                      <th className="align-middle" rowSpan={2}>
                        IS Sieve (mm)
                      </th>
                      <th className="align-middle" rowSpan={2}>
                        Weight Retained ( Gm.)
                      </th>
                      <th className="align-middle" rowSpan={2}>
                        % age wt. Retained
                      </th>
                      <th className="align-middle" rowSpan={2}>
                        Cummulative % age Retained
                      </th>
                      <th className="align-middle" rowSpan={2}>
                        Cummulative % age Passing
                      </th>
                      <th className="align-middle" colSpan={3}>
                        Passing Requirement
                        <br />
                        <small>as per IS : 383</small>
                      </th>
                    </tr>
                    <tr>
                      <th className="align-middle">Zone I</th>
                      <th className="align-middle">Zone II</th>
                      <th className="align-middle">Zone III</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((tData, index) => (
                      <tr key={index}>
                        <th>{tData.sieve}</th>
                        <td>
                          <input
                            type="number"
                            value={tData.weight}
                            onInput={(event) => handleWeight(event, index)}
                          />
                        </td>
                        <td>{tData.percent_wt.toFixed(2)}</td>
                        <td>{tData.commulative_retained.toFixed(2)}</td>
                        <td>{tData.commulative_passing.toFixed(2)}</td>
                        <td className="text-center">{tData?.zone1 ? tData.zone1.join(" - ") : ''}</td>
                        <td className="text-center">{tData?.zone2 ? tData.zone2.join(" - ") : ''}</td>
                        <td className="text-center">{tData?.zone3 ? tData.zone3.join(" - ") : ''}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <td>{totalWeight}</td>
                      <td colSpan={2} className="text-start">Gram</td>
                      <td colSpan={4} className="text-start">{totalPassing}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateStoneDust;
