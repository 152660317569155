import { Field, Form, Formik } from "formik";
import { Alert, Button, InputGroup } from "react-bootstrap";

import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { doLogin } from "../../../redux/actions/login";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import companyServices from "../../../redux/services/company";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const Login = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [pVisible, setPVisible] = useState(false);
  const dispatch = useDispatch();

  const login = useSelector((state) => state?.auth);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (login?.loginError) {
      setError(login.loginError);
    } else {
      setError(null);
    }

    if (login?.user && login?.token) {
      sessionStorage.setItem("@user", JSON.stringify(login?.user));
      sessionStorage.setItem("@token", login?.token);

      navigate("/admin");
    }
  }, [login, navigate]);

  useEffect(() => {
    async function fetchCompanies() {
      let response = await companyServices.view();
      if (response.status) {
        setCompanies(response?.data);
      }
    }
    fetchCompanies();
  }, []);

  return (
    <div className="vh-100 bg-light d-flex justify-content-center align-items-center">
      <div className={style.loginForm}>
        <div className={`bg-white shadow rounded p-3 ${style.loginBody}`}>
          <h3>Welcome to CMTMS! 👋</h3>
          <p className="mb-5">Please sign-in to your account.</p>
          {error ? <Alert variant="danger">{error}</Alert> : null}
          <Formik
            initialValues={{
              login: "",
              password: "",
              admin_id: null,
              type: "admin",
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(doLogin(values)).then(() => {
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div className={`mb-3`}>
                  <label className="me-3">
                    <Field type="radio" name="type" value="admin" /> Admin
                  </label>
                  <label className="me-3">
                    <Field type="radio" name="type" value="user" /> User
                  </label>
                </div>
                {values.type === "user" ? (
                  <div className={`mb-3`}>
                    <label htmlFor="admin_id" className="form-label">
                      Select Company
                    </label>
                    <Field
                      component="select"
                      name="admin_id"
                      className="form-select"
                      id="admin_id"
                    >
                      <option value="">Select Company</option>
                      {companies.map((c, index) => (
                        <option value={c.id} key={index}>
                          {c.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                ) : null}
                <div className={`mb-3`}>
                  <label htmlFor="login" className="form-label">
                    Username
                  </label>
                  <Field
                    name="login"
                    placeholder="Enter your username"
                    className="form-control"
                    id="login"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <InputGroup>
                    <Field
                      type={pVisible ? "text" : "password"}
                      name="password"
                      placeholder="············"
                      className="form-control"
                      id="password"
                    />
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={() => setPVisible((v) => !v)}
                    >
                      {pVisible ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </Button>
                  </InputGroup>
                </div>
                <div className="d-grid">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    {!isSubmitting ? "Sign In" : "Authenticating, wait..."}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
