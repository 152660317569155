import {
  Breadcrumb,
  Button,
  Col,
  FormControl,
  FormLabel,
  Spinner,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import formServices from "../../../redux/services/formServices";
import materialTypeServices from "../../../redux/services/materialTypeServices";
import { FiSearch } from "react-icons/fi";
import LaravelPagination from "../../../components/LaravelPagination";
import { useSelector } from "react-redux";
import companyServices from "../../../redux/services/company";

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [mTypes, setMTypes] = useState([]);
  const [mTypes2, setMTypes2] = useState([]);
  const [mTypes3, setMTypes3] = useState([]);
  const [companies, setCompanies] = useState([]);

  const user = useSelector((state) => state?.auth?.user);

  const [search, setSearch] = useState({
    mType: "",
    mType2: "",
    mType3: "",
    from_date: "",
    to_date: "",
    tower: "",
    floor: "",
    company: "",
  });

  async function fetchForms(page = 1) {
    setLoading(true);
    let response = await formServices.view({ ...search, page });
    setLoading(false);

    if (response.status) {
      setForms(response?.data);
    }
  }

  useEffect(() => {
    async function fetchParents() {
      let response = await materialTypeServices.view({ type: "root" });

      if (response.status) {
        setMTypes(response.data);
        setMTypes2([]);
        setMTypes3([]);
      }
    }

    async function fetchCompanies() {
      let response = await companyServices.view();

      if (response.status) {
        setCompanies(response.data);
      }
    }

    fetchForms();
    if (refresh === 0) {
      fetchParents();
      fetchCompanies();
    }
  }, [refresh]);

  useEffect(() => {
    async function fetchChildren(parent_id) {
      let response = await materialTypeServices.view({ parent_id });

      if (response.status) {
        setMTypes2(response.data);
      }
    }
    if (search.mType) fetchChildren(search.mType);
    else {
      setMTypes2([]);
      setMTypes3([]);
    }
    async function fetchChildren2(parent_id) {
      let response = await materialTypeServices.view({ parent_id });

      if (response.status) {
        setMTypes3(response.data);
      }
    }
    if (search.mType2) fetchChildren2(search.mType2);
    else {
      setMTypes3([]);
    }
  }, [search.mType, search.mType2]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await formServices.destory(id);

        if (response.status) {
          setRefresh((r) => parseInt(r) + 1);
        }
      }
    });
  };

  return (
    <div>
      <h1>Form</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Form</Breadcrumb.Item>
      </Breadcrumb>

      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">View Forms</h4>
        <div className="row">
          {user?.role === "admin" ? (
            <Col sm={4} lg={2} className="mb-3">
              <label htmlFor="material_type_id" className="form-label">
                By Company
              </label>
              <select
                type="text"
                className="form-select"
                onChange={(e) => {
                  setSearch((search) => ({
                    ...search,
                    company: e.target.value,
                  }));
                }}
                value={search?.company}
              >
                <option value="">Select Company</option>
                {companies.map((company, index) => (
                  <option value={company.id} key={index}>
                    {company.name}
                  </option>
                ))}
              </select>
            </Col>
          ) : null}
          <Col sm={4} lg={2} className="mb-3">
            <FormLabel>By Tower</FormLabel>
            <FormControl
              type="search"
              placeholder="Search By Tower"
              onInput={(e) =>
                setSearch((search) => ({
                  ...search,
                  tower: e.target.value,
                }))
              }
            />
          </Col>
          <Col sm={4} lg={2} className="mb-3">
            <FormLabel>By Floor</FormLabel>
            <FormControl
              type="search"
              placeholder="Search By Floor"
              onInput={(e) =>
                setSearch((search) => ({
                  ...search,
                  floor: e.target.value,
                }))
              }
            />
          </Col>
          <Col sm={4} lg={2} className="mb-3">
            <label htmlFor="material_type_id" className="form-label">
              Select Form *
            </label>
            <select
              type="text"
              className="form-select"
              onChange={(e) => {
                setSearch((search) => ({ ...search, mType: e.target.value }));
              }}
              value={search?.mType}
            >
              <option value="">Select Form</option>
              {mTypes.map((mType, index) => (
                <option value={mType.id} key={index}>
                  {mType.name}
                </option>
              ))}
            </select>
          </Col>

          {mTypes2.length ? (
            <div className="col-sm-4 col-lg-2">
              <div className="mb-3">
                <label htmlFor="material_type_id2" className="form-label">
                  Select Form 2 *
                </label>
                <select
                  type="text"
                  className="form-select"
                  name="material_type_id2"
                  id="material_type_id2"
                  onChange={(e) => {
                    setSearch((search) => ({
                      ...search,
                      mType2: e.target.value,
                    }));
                  }}
                  value={search?.mType2}
                >
                  <option value="">Select Form 2</option>
                  {mTypes2.map((mType, index) => (
                    <option value={mType.id} key={index}>
                      {mType.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}

          {mTypes3.length ? (
            <div className="col-sm-4 col-lg-2">
              <div className="mb-3">
                <label htmlFor="material_type_id3" className="form-label">
                  Select Form 3 *
                </label>
                <select
                  type="text"
                  className="form-select"
                  name="material_type_id3"
                  id="material_type_id3"
                  onChange={(e) => {
                    setSearch((search) => ({
                      ...search,
                      mType3: e.target.value,
                    }));
                  }}
                  value={search?.mType3}
                >
                  <option value="">Select Form</option>
                  {mTypes3.map((mType, index) => (
                    <option value={mType.id} key={index}>
                      {mType.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}

          <div className="col-sm-8 col-lg-4 mb-3">
            <FormLabel>Date Range</FormLabel>
            <div className="input-group">
              <FormControl
                type="date"
                placeholder="From Date"
                onChange={(e) =>
                  setSearch((s) => ({ ...s, from_date: e.target.value }))
                }
                value={search?.from_date}
              />
              <FormControl
                type="date"
                placeholder="To Date"
                onChange={(e) =>
                  setSearch((s) => ({ ...s, to_date: e.target.value }))
                }
                value={search?.to_date}
              />
            </div>
          </div>

          <div className="col-sm-4 col-lg-2 align-self-end ms-auto text-end mb-3">
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => setRefresh((r) => r + 1)}
            >
              <FiSearch className="me-2" />
              Search
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner variant="primary" />
          </div>
        ) : (
          <div>
            {forms?.data?.length ? (
              <div>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      {user?.role === "admin" ? <th>Company</th> : null}
                      <th>Date</th>
                      <th>Form Name</th>
                      <th>Tower</th>
                      <th>Floor</th>
                      <th>Engineer</th>
                      <th>Download</th>
                      {user?.role !== "user" ? <th>Actions</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {forms?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + forms?.from}.</td>
                        {user?.role === "admin" ? (
                          <td>{item?.user?.admin?.name}</td>
                        ) : null}

                        <td>{item.date}</td>
                        <td>
                          <strong>{item.material_type?.name ?? ""}</strong>
                          {item.material_type2?.name ? (
                            <>
                              <br /> &raquo; {item.material_type2.name}
                            </>
                          ) : (
                            ""
                          )}
                          {item.material_type3?.name ? (
                            <>
                              <br /> &raquo; {item.material_type3.name}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{item.tower}</td>
                        <td>{item.floor}</td>
                        <td>
                          <b>Eng:</b> {item.eng_name}
                          <br />
                          <b>Q. Eng:</b> {item.quality_eng_name}
                        </td>
                        <td>
                          <a
                            href={item.pdf_file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View / Download
                          </a>
                        </td>
                        {user?.role !== "user" ? (
                          <td>
                            <Link
                              to={`/admin/form/edit/${item.id}`}
                              className="btn btn-link text-primary"
                            >
                              <MdModeEdit />
                            </Link>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() => handleDelete(item.id)}
                            >
                              <MdDelete />
                            </Button>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <LaravelPagination items={forms} fetchData={fetchForms} />
              </div>
            ) : (
              <div>No record(s) found.</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
