import { Breadcrumb } from "react-bootstrap";
import ZoneForm from "./Form";
import { Link } from "react-router-dom";

const EditZone = () => {
  return (
    <div>
      <h1>Edit Zone</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Edit Zone</Breadcrumb.Item>
      </Breadcrumb>

      <ZoneForm />
    </div>
  );
};

export default EditZone;
