import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Spinner, Table } from "react-bootstrap";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import userServices from "../../../redux/services/userServices";
import Swal from "sweetalert2";
import LaravelPagination from "../../../components/LaravelPagination";
import { useSelector } from "react-redux";

const User = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const user = useSelector((state) => state?.auth?.user);

  async function fetchCompanies(page = 1) {
    setLoading(true);
    setError(null);
    let response = await userServices.view(`page=${page}`);
    setLoading(false);
    if (response.status) {
      setCompanies(response?.data);
    } else {
      if (response?.data?.data?.message)
        setError(response?.data?.data?.message);
      else if (response?.error?.message) setError(response?.error?.message);
    }
  }
  useEffect(() => {
    fetchCompanies();
  }, [refresh]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await userServices.destory(id);

        if (response.status) {
          setRefresh((r) => parseInt(r) + 1);
        }
      }
    });
  };

  return (
    <div>
      <h1>Users</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>User</Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">View Users</h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        {!loading ? (
          <div>
            {companies?.data?.length ? (
              <div>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      {user.role === "admin" ? <th>Company</th> : null}
                      <th>Name</th>
                      <th>Username</th>
                      {/* <th>Role</th> */}
                      <th>Photo</th>
                      <th>Sign</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies?.data?.map((row, index) => (
                      <tr key={index}>
                        <td>{index + companies.from}.</td>
                        {user.role === "admin" ? (
                          <td>{row?.admin?.name}</td>
                        ) : null}
                        <td>{row.name}</td>
                        <td>
                          <strong>{row.login}</strong>
                          <br />
                          <small>({row?.role?.name})</small>
                        </td>
                        <td className="text-center">
                          {row?.image ? (
                            <img
                              src={row.image}
                              alt={row.name}
                              style={{ maxHeight: 48 }}
                            />
                          ) : (
                            "Not Uploaded"
                          )}
                        </td>
                        <td>
                          {row?.sign_image ? (
                            <img
                              src={row.sign_image}
                              alt={row.name}
                              style={{ maxHeight: 48 }}
                            />
                          ) : (
                            "Not Uploaded"
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/admin/user/edit/${row.id}`}
                            className="btn btn-link text-primary"
                          >
                            <MdModeEdit />
                          </Link>
                          <Button
                            variant="link"
                            className="text-danger"
                            onClick={() => handleDelete(row.id)}
                          >
                            <MdDelete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <LaravelPagination
                  items={companies}
                  fetchData={fetchCompanies}
                />
              </div>
            ) : (
              <div>No record(s) found.</div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
