import ApiExecute from ".";

const login = async (payload) => {
  return await ApiExecute("login", {
    method: "POST",
    data: payload,
  });
};

const logout = async () => {
  return await ApiExecute("logout");
};

const loginServices = {
  login,
  logout,
};

export default loginServices;
