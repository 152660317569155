import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormFormat from "./Form";

const AddForm = () => {
  return (
    <div>
      <h1>Add Form</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Add Form</Breadcrumb.Item>
      </Breadcrumb>

      <FormFormat />
    </div>
  );
};

export default AddForm;
