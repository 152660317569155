import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Alert,
  Breadcrumb,
  Button,
  FormCheck,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import galleryServices from "../../../../redux/services/galleryServices";

const CreateGallery = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();
  const [image, setImage] = useState(null);

  const [error, setError] = useState(null);

  const handleImage = (e, max_size = 1024) => {
    const [file] = e.target.files;

    if (file.type.match(/image.*/)) {
      // Load the image
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var canvas = document.createElement("canvas"),
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL("image/webp");

          formikRef.current.setFieldValue("image", dataUrl);

          setImage(dataUrl);
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const gallerySchema = Yup.object().shape({
    title: Yup.string().required(),
    type: Yup.string().required(),
  });

  useEffect(() => {
    async function fetchGalleryDetails() {
      let response = await galleryServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("title", response.data.title);
          formikRef.current.setFieldValue("type", response.data.type);
          formikRef.current.setFieldValue(
            "description",
            response.data.description
          );
          formikRef.current.setFieldValue(
            "youtube_id",
            response.data.youtube_id
          );

          if (response?.data?.image) setImage(response.data.image);
        }
      }
    }

    if (id) {
      fetchGalleryDetails();
    }
  }, [id]);

  return (
    <div>
      <h1>{id ? "Edit" : "Add"} Gallery</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/company" }}>
          Gallery
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id ? "Edit" : "Add"} Gallery</Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">
          Add Gallery Account
        </h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        <Formik
          innerRef={formikRef}
          initialValues={{
            title: "",
            type: "image",
            youtube_id: null,
            image: null,
            file: null,
            description: "",
          }}
          validationSchema={gallerySchema}
          onSubmit={async (values, { setSubmitting }) => {
            setError(null);
            let response = !id
              ? await galleryServices.store(values)
              : await galleryServices.update(id, values);
            setSubmitting(false);

            if (response.status) {
              navigate("/admin/gallery");
            } else {
              // console.log("admin error", response?.data?.data);
              if (response?.data?.data?.message)
                setError(response?.data?.data?.message);
              else if (response?.error?.message)
                setError(response?.error?.message);
            }
          }}
        >
          {({
            isSubmitting,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
          }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title *
                </label>
                <Field
                  name="title"
                  className="form-control"
                  placeholder="Title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                  id="title"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type *
                </label>

                <div>
                  <div className="inline-radio">
                    <FormCheck
                      inline
                      type="radio"
                      label="Image"
                      value={"image"}
                      id="typeImage"
                      name="type"
                      checked={values.type === "image"}
                      onChange={handleChange}
                    />
                    <FormCheck
                      inline
                      type="radio"
                      label="Video"
                      value={"video"}
                      id="typeVideo"
                      name="type"
                      checked={values.type === "video"}
                      onChange={handleChange}
                    />
                    <FormCheck
                      inline
                      type="radio"
                      label="File"
                      value={"file"}
                      id="typeFile"
                      name="type"
                      checked={values.type === "file"}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                {values.type === "image" ? (
                  <>
                    {image ? (
                      <div
                        id="preview"
                        style={{
                          width: 100,
                        }}
                        className="mb-3"
                      >
                        <img
                          src={image}
                          alt="Preview "
                          className="w-100 rounded border"
                        />
                      </div>
                    ) : null}
                    <FormLabel>Choose Image</FormLabel>
                    <FormControl
                      type="file"
                      accept="image/*"
                      onChange={handleImage}
                    />
                  </>
                ) : (
                  <>
                    {values.type === "file" ? (
                      <>
                        <FormLabel>Choose File</FormLabel>
                        <FormControl
                          type="file"
                          onChange={(e) =>
                            setFieldValue("file", e.target.files[0])
                          }
                        />
                      </>
                    ) : (
                      <>
                        <FormLabel>Youtube Video ID</FormLabel>
                        <FormControl
                          type="text"
                          placeholder="Enter Youtube ID"
                          name="youtube_id"
                          value={values?.youtube_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description *
                </label>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  name="description"
                  rows={8}
                  value={values?.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-danger"
                />
              </div>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateGallery;
