import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import companyServices from "../../../../redux/services/company";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const CreateCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();

  const [error, setError] = useState(null);
  const [pVisible, setPVisible] = useState(false);

  useEffect(() => {
    async function fetchCompanyDetails() {
      let response = await companyServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("name", response.data.name);
          formikRef.current.setFieldValue("login", response.data.login);
        }
      }
    }

    if (id) {
      fetchCompanyDetails();
    }
  }, [id]);

  return (
    <div>
      <h1>{id ? "Edit" : "Add"} Company</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/company" }}>
          Company
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id ? "Edit" : "Add"} Company</Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">
          Add Company Account
        </h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        <Formik
          innerRef={formikRef}
          initialValues={{
            name: "",
            login: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "* Please enter name.";
            }
            if (!values.login) {
              errors.login = "* Please enter username.";
            }
            if (!values.password && !id) {
              errors.password = "* Please enter password.";
            } else if (
              values.password.length >= 1 &&
              values.password.length < 8
            ) {
              errors.password = "* Please enter min 8 character password.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError(null);
            let response = !id
              ? await companyServices.store(values)
              : await companyServices.update(id, values);
            setSubmitting(false);

            if (response.status) {
              navigate("/admin/company");
            } else {
              // console.log("admin error", response?.data?.data);
              if (response?.data?.data?.message)
                setError(response?.data?.data?.message);
              else if (response?.error?.message)
                setError(response?.error?.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name *
                </label>
                <Field
                  name="name"
                  className="form-control"
                  placeholder="Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                  id="name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="loginName" className="form-label">
                  Username *
                </label>
                <Field
                  name="login"
                  className="form-control"
                  placeholder="Username"
                  id="loginName"
                />
                <ErrorMessage
                  name="login"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password *
                </label>
                <InputGroup>
                  <Field
                    type={pVisible ? "text" : "password"}
                    name="password"
                    className="form-control"
                    placeholder="*******"
                    id="password"
                  />

                  <Button
                    type="button"
                    variant="outline-primary"
                    onClick={() => setPVisible((v) => !v)}
                  >
                    {pVisible ? <BsEyeSlashFill /> : <BsEyeFill />}
                  </Button>
                </InputGroup>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  "Wait..."
                ) : (
                  <>
                    <FiCheck /> {id ? "Update" : "Create"}
                  </>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCompany;
