import { ErrorMessage, Field, Form, Formik } from "formik";
import { Alert, Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import userServices from "../../../../redux/services/userServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import roleServices from "../../../../redux/services/role";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const CreateUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();

  const [error, setError] = useState(null);
  const [pVisible, setPVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const [photo, setPhoto] = useState({
    image: null,
    sign_image: null,
  });

  const handlePhoto = (e, field, max_size = 300) => {
    const [file] = e.target.files;

    if (file.type.match(/image.*/)) {
      // Load the image
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var canvas = document.createElement("canvas"),
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL("image/jpeg");

          formikRef.current.setFieldValue(field, dataUrl);

          setPhoto((photo) => ({
            ...photo,
            [field]: dataUrl,
          }));
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    async function fetchUserDetails() {
      let response = await userServices.show(id);

      if (response.status) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("name", response.data.name);
          formikRef.current.setFieldValue("login", response.data.login);
          formikRef.current.setFieldValue("role_id", response.data.role_id);

          setPhoto({
            image: response.data.image,
            sign_image: response.data.sign_image,
          });
        }
      }
    }

    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  useEffect(() => {
    async function fetchRoles() {
      let response = await roleServices.view();

      if (response.status) {
        setRoles(response?.data);
      }
    }
    fetchRoles();
  }, []);

  return (
    <div>
      <h1>{id ? "Edit" : "Add"} User</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/user" }}>
          User
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id ? "Edit" : "Add"} User</Breadcrumb.Item>
      </Breadcrumb>
      <div className="bg-white shadow-sm p-3 rounded">
        <h4 className="text-primary pb-2 border-bottom mb-3">Add User</h4>

        {error ? <Alert variant="danger">{error}</Alert> : null}

        <Formik
          innerRef={formikRef}
          initialValues={{
            role_id: null,
            name: "",
            login: "",
            password: "",
            image: null,
            sign_image: null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.role_id) {
              errors.role_id = "* Please select role.";
            }
            if (!values.name) {
              errors.name = "* Please enter name.";
            }
            if (!values.login) {
              errors.login = "* Please enter username.";
            }
            if (!values.password && !id) {
              errors.password = "* Please enter password.";
            } else if (
              values.password.length >= 1 &&
              values.password.length < 8
            ) {
              errors.password = "* Please enter min 8 character password.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError(null);
            let response = !id
              ? await userServices.store(values)
              : await userServices.update(id, values);
            setSubmitting(false);

            if (response.status) {
              navigate("/admin/user");
            } else {
              // console.log("admin error", response?.data?.data);
              if (response?.data?.data?.message)
                setError(response?.data?.data?.message);
              else if (response?.error?.message)
                setError(response?.error?.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-sm-8 col-lg-9">
                  <div className="mb-3">
                    <label htmlFor="role_id" className="form-label">
                      Select Role
                    </label>
                    <Field
                      component="select"
                      name="role_id"
                      id="role_id"
                      className="form-select"
                    >
                      <option value="">Select Role</option>
                      {roles.map((role, index) => (
                        <option value={role.id} key={index}>
                          {role.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="role_id"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                      id="name"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="loginName" className="form-label">
                      Username *
                    </label>
                    <Field
                      name="login"
                      className="form-control"
                      placeholder="Username"
                      id="loginName"
                    />
                    <ErrorMessage
                      name="login"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password *
                    </label>
                    <InputGroup>
                      <Field
                        type={pVisible ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="*******"
                        id="password"
                      />
                      <Button
                        type="button"
                        variant="outline-primary"
                        onClick={() => setPVisible((v) => !v)}
                      >
                        {pVisible ? <BsEyeSlashFill /> : <BsEyeFill />}
                      </Button>
                    </InputGroup>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                    className="px-5"
                  >
                    {isSubmitting ? (
                      "Wait..."
                    ) : (
                      <>
                        <FiCheck /> {id ? "Update" : "Create"}
                      </>
                    )}
                  </Button>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <h4>Upload Photo</h4>
                  <label htmlFor="image" className="mb-3 w-100">
                    <img
                      src={photo?.image ?? "/images/no_image.jpg"}
                      alt="Choose"
                      className="w-100 border rounded"
                      style={{
                        height: 150,
                        objectFit: "contain",
                      }}
                    />
                    <input
                      type="file"
                      id="image"
                      accept="*/image"
                      className="d-none"
                      onChange={(e) => handlePhoto(e, "image")}
                    />
                  </label>
                  <div className="d-grid mb-3">
                    <label htmlFor="image" className="btn btn-primary">
                      Choose Image
                    </label>
                  </div>

                  <h4>Upload Signature</h4>
                  <label htmlFor="sign_image" className="mb-3 w-100">
                    <img
                      src={photo?.sign_image ?? "/images/no_image.jpg"}
                      alt="Choose"
                      className="w-100 border rounded"
                      style={{
                        height: 50,
                        objectFit: "contain",
                      }}
                    />
                    <input
                      type="file"
                      id="sign_image"
                      accept="*/image"
                      className="d-none"
                      onChange={(e) => handlePhoto(e, "sign_image", 100)}
                    />
                  </label>
                  <div className="d-grid mb-3">
                    <label htmlFor="sign_image" className="btn btn-primary">
                      Choose Signature
                    </label>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUser;
