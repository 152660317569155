import { Link, Outlet } from "react-router-dom";
import "./style.scss";
import { useEffect } from "react";
import $ from "jquery";

const Layout = () => {
  const changeHeader = () => {
    let scrollTop = $(window).scrollTop();

    if (scrollTop > 100) {
      $("header").addClass("bg-white shadow").removeClass("text-white");
      $("header .logo img").attr("src", "images/logo.png");
      $("header .btn")
        .removeClass("btn-outline-light")
        .addClass("btn-outline-dark");
    } else {
      $("header").removeClass("bg-white shadow").addClass("text-white");
      $("header .logo img").attr("src", "images/logo_white.png");
      $("header .btn")
        .removeClass("btn-outline-dark")
        .addClass("btn-outline-light");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeHeader);

    return () => {
      window.removeEventListener("scroll", changeHeader);
    };
  }, []);

  return (
    <>
      <header class="fixed-top text-white">
        <div class="container">
          <div class="d-flex align-items-center py-3">
            <div class="logo">
              <Link to="/">
                <img src="images/logo_white.png" alt="CMTMS" />
              </Link>
            </div>
            <div class="ms-auto">
              <Link
                to={"/gallery"}
                class="btn btn-outline-light rounded-pill px-4 me-3"
              >
                Resource
              </Link>
              <Link
                to={"/login"}
                class="btn btn-outline-light rounded-pill px-4"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </header>

      <Outlet />

      <section class="py-5 call-to-action text-center text-white">
        <p>CALL NOW</p>
        <a href="tel: +91 8826235698">+91 8826235698</a>
      </section>

      <footer class="text-center py-5">
        &copy; 2023. Construction Material Testing Management System
      </footer>
    </>
  );
};

export default Layout;
