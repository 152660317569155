import ApiExecute from ".";

const view = async (query = "") => {
  return await ApiExecute(`user?${query}`);
};

const store = async (data) => {
  return await ApiExecute("user", {
    method: "POST",
    data,
  });
};

const show = async (id) => {
  return await ApiExecute(`user/${id}`);
};

const update = async (id, data) => {
  data._method = "PUT";
  return await ApiExecute(`user/${id}`, {
    method: "POST",
    data,
  });
};

const destory = async (id) => {
  return await ApiExecute(`user/${id}`, {
    method: "POST",
    data: {
      _method: "DELETE",
    },
  });
};

const userServices = {
  view,
  store,
  show,
  update,
  destory,
};

export default userServices;
