import { Breadcrumb, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import roleServices from "../../../redux/services/role";
import RoleForm from "./Form";
import Swal from "sweetalert2";

const Role = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    console.log("refresh:", refresh);
    async function fetchRoles() {
      setLoading(true);
      let response = await roleServices.view();
      setLoading(false);

      if (response.status) {
        setRoles(response?.data);
      }
    }

    fetchRoles();
  }, [refresh]);

  const refreshRoles = () => {
    setRefresh((r) => parseInt(r) + 1);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await roleServices.destory(id);

        if (response.status) {
          setRefresh((r) => parseInt(r) + 1);
        }
      }
    });
  };

  return (
    <div>
      <h1>Role</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Master</Breadcrumb.Item>
        <Breadcrumb.Item active>Role</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={4}>
          <RoleForm fetchRoles={refreshRoles} />
        </Col>

        <Col sm={8}>
          <div className="bg-white shadow-sm p-3 rounded">
            <h4 className="text-primary pb-2 border-bottom mb-3">View Roles</h4>
            {loading ? (
              <div className="text-center">
                <Spinner variant="primary" />
              </div>
            ) : (
              <Table bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Level</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {roles?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item.name}</td>
                      <td>{item.level}</td>
                      <td>
                        <Link
                          to={`/admin/role/edit/${item.id}`}
                          className="btn btn-link text-primary"
                        >
                          <MdModeEdit />
                        </Link>
                        <Button
                          variant="link"
                          className="text-danger"
                          onClick={() => handleDelete(item.id)}
                        >
                          <MdDelete />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Role;
