import { Button, Container, Dropdown, Image } from "react-bootstrap";
import style from "./style.module.scss";
import { SlMenu } from "react-icons/sl";
import { AiOutlineLock, AiOutlinePoweroff } from "react-icons/ai";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../../../redux/actions/login";
import { Link } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.user);

  const customToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="white" className="p-0" ref={ref} onClick={onClick}>
      {children}
    </Button>
  ));

  return (
    <header className="m-3 py-3 rounded bg-white shadow-sm">
      <Container fluid>
        <div className="d-flex align-items-center">
          <Button variant="white" className="border">
            <SlMenu />
          </Button>
          {user?.role === "user" ? (
            <div className="ms-3 h1 mb-0 fw-bold text-uppercase">
              {user?.admin?.name}
            </div>
          ) : null}
          <div className="ms-auto">
            <Dropdown>
              <Dropdown.Toggle as={customToggle}>
                <div className="d-flex align-items-center">
                  <Image
                    src="/admin/imgs/profile.png"
                    className={`rounded-circle ${style.profileImage}`}
                  />
                  <div className="ms-2">Hi, {user?.name}</div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={"/admin/change-password"}>
                  <AiOutlineLock className="me-2" />
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={(event) => dispatch(doLogout(event))}>
                  <AiOutlinePoweroff className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
