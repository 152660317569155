import ApiExecute from ".";

const webView = async (query = "") => {
  return await ApiExecute(`web-gallery?${query}`);
};

const view = async (query = "") => {
  return await ApiExecute(`gallery?${query}`);
};

const store = async (data) => {
  return await ApiExecute("gallery", {
    method: "POST",
    data,
  });
};

const show = async (id) => {
  return await ApiExecute(`gallery/${id}`);
};

const update = async (id, data) => {
  data._method = "PUT";
  return await ApiExecute(`gallery/${id}`, {
    method: "POST",
    data,
  });
};

const destory = async (id) => {
  return await ApiExecute(`gallery/${id}`, {
    method: "POST",
    data: {
      _method: "DELETE",
    },
  });
};

const galleryServices = {
  webView,
  view,
  store,
  show,
  update,
  destory,
};

export default galleryServices;
