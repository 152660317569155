const Home = () => {
  return (
    <div className="">
      <section class="banner text-white text-center">
        <div class="overlay"></div>
        <video
          src="video/background.mp4"
          class="video-bg"
          loop
          autoPlay
          muted
        ></video>

        <div class="banner-content">
          <h1 class="wow animate__backInDown text-white" data-wow-duration="2s">
            CMTMS
          </h1>
          <p class="wow animate__fadeInUp" data-wow-duration="2s">
            Construction Material Testing Management System
          </p>
        </div>
      </section>

      <section class="py-5">
        <div class="container">
          <div class="row align-items-end">
            <div class="col-sm-4">
              <img src="images/about.png" alt="About CMTMS" class="img-fluid" />
            </div>
            <div class="col-sm-8">
              <h2 class="text-center">About Us</h2>
              <p>
                CMTMS is integrated construction material testing management
                system for materials used for the construction of Buildings,
                Highways, Bridges and Tunnels. This system helps in controlling
                the quality of input material used for construction purposes.
                Which helps in overall quality control before and during
                construction so that strong and durable structure can be build.
              </p>
              <p>
                The CMTMS provides full chain of transparency starting from
                sample collection and tracking throughout its lifecycle of use
                in construction as product. User can analyze, schedule, perform
                and report test results.
              </p>
              <p>
                It gives real- time visibility of tests and results. There is
                pre-defined material specification as per relevant codes and
                guidelines. Data entry is allowed though lab test form and data
                analyzed as per the guidelines of relevant codes and result of
                material displayed and stored for future use.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="row g-0">
          <div class="col-sm-6">
            <div class="service">
              <img src="images/1.jpeg" alt="" class="img-fluid" />
            </div>
            <h3 class="text-center bg-white py-3">Plant</h3>
          </div>
          <div class="col-sm-6">
            <div class="service">
              <img src="images/Picture1.jpg" alt="" />
            </div>
            <h3 class="text-center bg-white py-3">Building</h3>
          </div>

          <div class="col-sm-6">
            <div class="service">
              <img src="images/Picture2.jpg" alt="" />
            </div>
            <h3 class="text-center bg-white py-3">Bridge</h3>
          </div>

          <div class="col-sm-6">
            <div class="service">
              <img src="images/Picture3.jpg" alt="" />
            </div>
            <h3 class="text-center bg-white py-3">Tunnel</h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
