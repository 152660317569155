const NotFound = () => {
  return (
    <div>
      <h1>404 Error! Page Not Found</h1>
      <p>The page you're looking for is not found or broken link</p>
    </div>
  );
};

export default NotFound;
