import { Col, Row } from "react-bootstrap";
import style from "./style.module.scss";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const user = useSelector((state) => state?.auth?.user);

  return (
    <Row>
      <Col sm={8}>
        <div className="px-3 bg-white rounded-3 shadow-sm border-0">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="text-primary">Hi {user?.name}! 🎉</h4>
              <h6 className="text-secondary mb-4">({user?.role})</h6>
              {user?.role === "admin" ? (
                <p className="mb-0">Welcome to admin control panel.</p>
              ) : null}
            </div>
            <img
              src="/admin/imgs/man-with-laptop-light.png"
              className={`img img-fluid ${style.dashboardImage}`}
              alt="Man with Laptop"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Dashboard;
